import { Helmet } from "react-helmet"

interface CustomHelmetProps {
    title?: string;
    description?: string;
    themeColor?: string;
    twitterImage?: string;
    twitterSite?: string;
    noIndex?: boolean;
    children?: React.ReactNode;
}

const CustomHelmet = (props: CustomHelmetProps) => {
    const { title, description, themeColor, twitterImage, twitterSite, noIndex, children } = props;
    return (
        <Helmet>
            <title>{title ? `${title} | Karl Jones` : "Karl Jones"}</title>
            <meta name="og:title" content={title ? title : "Karl Jones"} />

            {/** Description */}
            {description && <meta name="description" content={description} />}
            {description && <meta name="og:description" content={description} />}

            {/** Twitter */}
            <meta name="twitter:title" content={title ? title : "Karl Jones"} />
            {description && <meta name="twitter:description" content={description} />}
            <meta name="twitter:card" content="summary" />
            {twitterImage && <meta name="twitter:image" content={twitterImage} />}
            {twitterSite && <meta name="twitter:site" content={twitterSite} />}

            {/* Misc */}
            <link rel="canonical" href={window.location.href} />
            <meta name="og:url" content={window.location.href} />
            <meta name="theme-color" media="(prefers-color-scheme: dark)" content={themeColor ? themeColor : '#1f1f1f'} />
            <meta name="theme-color" media="(prefers-color-scheme: light)" content={themeColor ? themeColor : '#f7f7f7'} />
            {noIndex && <meta name="robots" content="noindex" />}

            {children}
        </Helmet>
    )
}

export default CustomHelmet;