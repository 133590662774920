import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ProjectProps } from "../../interfaces/ProjectProps";
import { SectionType } from "../../enums/SectionType";

import ErrorBoundary from "../../components/common/errorboundary";
import Icon from "../../components/sections/details/icon";
import Loading from "../../components/common/states/loading";
import Heading from "../../components/common/text/heading";
import Section from "../../components/sections/details/section";
import Links from "../../components/sections/details/links";
import CustomHelmet from "../../components/common/customhelmet/CustomHelmet";
import NoData from "../../components/common/nodata";

const Project = () => {
    const [loading, setLoading] = useState(true);
    const [project, setProject] = useState<ProjectProps | null>(null);

    const location = useLocation().pathname;

    const retrieveProjectData = async (shortcode: String) => {
        await fetch(`/api/data/projects/${shortcode}.json`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then((response) => response.json())
            .then((data) => {
                setProject(data);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error:', error);
                setLoading(false);
            });
    }

    useEffect(() => {
        const shortcode = location.split('/')[2];
        retrieveProjectData(shortcode);
    }, [location])

    if (loading) return <Loading />

    if (!loading && !project) {
        return <NoData redirectURL="/projects" />;
    }

    return (
        <ErrorBoundary>
            <div data-testid="Project" className="project">
                <CustomHelmet title={project?.title} description={project?.tagline} />

                {project?.images?.icon_id &&
                    <Icon icon_id={project?.images?.icon_id} icon_alt={project?.title} />
                }

                <Heading variant="h1" className="project-title" centered>{project?.title}</Heading>

                {project?.sections &&
                    project?.sections?.map((section, index) => {
                        return <Section title={section.title} type={SectionType[section.type.toUpperCase()]} details={section.details} key={index} />
                    })
                }

                {(project?.links && project?.links?.length > 0) &&
                    <Links links={project.links} />
                }
            </div>
        </ErrorBoundary>
    )
}

export default Project;