import { useEffect, useState } from "react";

import ErrorBoundary from "../../components/common/errorboundary"
import CustomHelmet from "../../components/common/customhelmet";
import Projects from "../../components/sections/projects";
import Loading from "../../components/common/states/loading";
import NoData from "../../components/common/nodata";
import { ProjectProps } from "../../interfaces/ProjectProps";

const AllProjects = () => {

    const [loading, setLoading] = useState(true);
    const [allProjectsData, setAllProjectsData] = useState<ProjectProps[]>();

    const retrieveAllExperienceData = async () => {
        await fetch(`/api/data/projects/all-projects.json`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then((response) => response.json())
            .then((data) => {
                setAllProjectsData(data);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error:', error);
                setLoading(false);
            });
    }

    useEffect(() => {
        retrieveAllExperienceData();
    }, []);

    if (loading) return <Loading />

    if (!loading && !allProjectsData) {
        return <NoData redirectURL="/" />;
    }

    return (
        <ErrorBoundary>
            <div data-testid="AllProjects" className="all-projects">
                <CustomHelmet title="Projects" />

                {allProjectsData && <Projects title="Featured Projects" projects={allProjectsData.filter((project) => project.featured)} />}
                {allProjectsData && <Projects title="All Projects" projects={allProjectsData?.filter((project) => !project.featured)} hide_icon />}
            </div>
        </ErrorBoundary>
    )
}

export default AllProjects;