import ErrorBoundary from "../errorboundary";

import { Link } from "react-router-dom";

interface NoDataProps {
    redirectTitle?: string;
    redirectURL: string;
}

const NoData = (props: NoDataProps) => {
    return (
        <ErrorBoundary>
            <div data-testid="NoData" className="no-data">
                <h3>Looks like you might have taken a wrong turn</h3>
                {props.redirectURL && <p>Go back to <Link to={props.redirectURL}>{props.redirectTitle ? props.redirectTitle : props.redirectURL}</Link></p>}
            </div>
        </ErrorBoundary>
    )
}

export default NoData;