import { useState, useEffect } from "react";

import ErrorBoundary from "../../components/common/errorboundary";
import CustomHelmet from "../../components/common/customhelmet";
import ExperiencesList from "../../components/sections/experiences-list";
import Loading from "../../components/common/states/loading";
import NoData from "../../components/common/nodata";

import { ExperienceProps } from "../../interfaces/ExperienceProps";

const AllExperience = () => {
    const [loading, setLoading] = useState(true);
    const [allExperienceData, setAllExperienceData] = useState<ExperienceProps[]>();

    const retrieveAllExperienceData = async () => {
        await fetch(`/api/data/experience/all-experience.json`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then((response) => response.json())
            .then((data) => {
                setAllExperienceData(data);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error:', error);
                setLoading(false);
            });
    }

    useEffect(() => {
        retrieveAllExperienceData();
    }, []);

    if (loading) return <Loading />

    if (!loading && !allExperienceData) {
        return <NoData redirectTitle="home" redirectURL="/" />;
    }
    return (
        <ErrorBoundary>
            <div data-testid="AllExperience" className="all-experience">
                <CustomHelmet title="Experience" />

                {allExperienceData && <ExperiencesList title="Experience" experiences={allExperienceData} />}
            </div>
        </ErrorBoundary>
    )
}

export default AllExperience;