import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import CustomHelmet from "../../components/common/customhelmet";
import Profile from "../../components/sections/profile";
import Projects from "../../components/sections/projects";
import Background from "../../components/sections/background";
import { ProjectProps } from "../../interfaces/ProjectProps";

const Home = () => {
    const [featuredProjects, setFeaturedProjects] = useState<ProjectProps[] | null>(null);

    const retrieveFeaturedProjects = async () => {
        await fetch(`/api/data/projects/featured-projects.json`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then((response) => response.json())
            .then((data) => {
                setFeaturedProjects(data);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    useEffect(() => {
        retrieveFeaturedProjects();
    }, []);

    return (
        <div data-testid="Home" className="home">
            <CustomHelmet description="Homepage for Karl Jones || Technical Support Manager || Software Developer" />
            <Profile />
            {featuredProjects &&
                <div>
                    <Projects projects={featuredProjects} />
                    <Link to="/projects" className="link-exclude-accent">View all projects</Link>
                </div>
            }
            <Background />
        </div>
    )
}

export default Home;