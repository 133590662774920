import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { EducationProps } from "../../interfaces/EducationProps";
import { SectionType } from "../../enums/SectionType";
import { StringUtils } from "../../utils/StringUtils";

import ErrorBoundary from "../../components/common/errorboundary";
import Icon from "../../components/sections/details/icon";
import Loading from "../../components/common/states/loading";
import Heading from "../../components/common/text/heading";
import Section from "../../components/sections/details/section";
import CustomHelmet from "../../components/common/customhelmet/CustomHelmet";
import NoData from "../../components/common/nodata";

const Education = () => {
    const [loading, setLoading] = useState(true);
    const [education, setEducation] = useState<EducationProps | null>(null);

    const location = useLocation().pathname;

    const retrieveEducationItem = async (shortcode: String) => {
        await fetch(`/api/data/education/${shortcode}.json`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then((response) => response.json())
            .then((data) => {
                setEducation(data);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error:', error);
                setLoading(false);
            });
    }

    useEffect(() => {
        const shortcode = location.split('/')[2];
        retrieveEducationItem(shortcode);
    }, [location]);

    if (loading) return <Loading />

    if (!loading && !education) {
        return <NoData redirectURL="/education" />;
    }

    return (
        <ErrorBoundary>
            <div data-testid="Education" className="education">
                <CustomHelmet title={education?.title} noIndex />

                {education?.images?.icon_id &&
                    <Icon icon_id={education?.images?.icon_id} icon_alt={education?.title} />
                }

                <Heading variant="h1" className="education-title" centered>{education?.title}</Heading>
                <Heading variant="h4" className="education-subtitle" centered>{StringUtils.createDateSubtitle(education?.organisation, education?.start_date, education?.end_date)}</Heading>

                {education?.sections &&
                    education?.sections?.map((section, index) => {
                        return <Section title={section.title} type={SectionType[section.type.toUpperCase()]} details={section.details} key={index} />
                    })
                }
            </div>
        </ErrorBoundary>
    )
}

export default Education;